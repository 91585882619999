/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CategoryWrapper, CategoryCard } from './styles';
import Thumbnail from './../Thumbnail';
import { OverlayTrigger, Popover } from "react-bootstrap";
import CreateChallengePoup from './challengeCreatePopup';
import { ImageUrl } from "../../utils/constants";
import { withTranslation } from 'react-i18next';

class BehaviorCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onHover: -1
    }
  }

  popover = (text) => (
    <Popover id="popover-basic"
      style={{
        width: "100%",
        padding: "9px 14px",
        background: "#FFFFFF",
        border: "1px solid #002F47",
        boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
      }}
    >
      <div className='text' style={{
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#002F47",
        textAlign: "center",
        display: "-webkit-box",
        WebkitLineClamp: 4,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{text}
      </div>
    </Popover> );
  
  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  onLaunchChallenge = () => {
    this.props.history.push('/company/challenges/launch')
  };

  wellnessCategories = [
    {
      name: 'Nutrition Challenge',
      tooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
      image: '/public/images/AdminChallenges/NutritionChallenge.png',
      id: 1,
      challengeCount: "9 Challenge"
    },
    {
      name: 'Fitness Challenge',
      tooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
      image: '/public/images/AdminChallenges/Fitness.png',
      id: 2,
      challengeCount: "9 Challenge"
    },
    {
      name: 'Workplace Challenge',
      tooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
      image: '/public/images/AdminChallenges/Workplace.png',
      id: 3,
      challengeCount: "9 Challenge"
    },
    {
      name: 'Stress Challenge',
      tooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
      image: '/public/images/AdminChallenges/StressChallenge.png',
      id: 4,
      challengeCount: "9 Challenge"
    },
    {
      name: 'Health Challenge',
      tooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
      image: '/public/images/AdminChallenges/HealthChallenge.png',
      id: 5,
      challengeCount: "9 Challenge"
    },
    {
      name: 'Lifestyle Challenge',
      tooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
      image: '/public/images/AdminChallenges/Lifestyle.png',
      id: 6,
      challengeCount: "9 Challenge"
    }
  ];

  render() {
    const { onHover } = this.state;
    const { history, wellbeingCategoriesData } = this.props;
    return (
      <CategoryWrapper>
        {wellbeingCategoriesData && wellbeingCategoriesData.map((category, index) => (
          <CategoryCard key={index} background={"white"} margin={(index+1)%3===0?"0 0px 25px 0":"0 25px 25px 0"}>
            <div className="image" onMouseEnter={() => this.onEnterMouse(category.id)} onMouseLeave={() => this.onLeaveMouse()}>
              <Thumbnail
                src={ImageUrl+'/'+category.new_icon}
                alt={"recommended card"}
                height={"250px"}
              />
              {onHover === category.id &&
                <div className='imageBackground'>
                  <div className="viewMore" onClick={history.location.pathname==="/challenges/categories"?() => history.push({pathname:`/challenges/behavior-categories/${category.category_name=="medical"?"health":category.category_name}`,state:{name:category.name}}):() => history.push({pathname:`/company/challenges/behavior-categories/${category.category_name=="medical"?"health":category.category_name}`,state:{name:category.name}})}>{this.props.t("View More")}</div>
                </div>
              }
            </div>
            <div className="categoryNameWrapper">
              <div className="categoryName">{this.props.t(category.name)}</div>
              <div className="tooltipIcon" >
                <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t(category.name))}>
                  <img src={ImageUrl+"/ChallengeDetailsScreen/toolTipIcon.png"} />
                </OverlayTrigger>
              </div>
              <div className="challengeCount">{this.props.t(category.count)}</div>
            </div>
          </CategoryCard>))}
        {<CreateChallengePoup/>}
      </CategoryWrapper>
    );
  }
}

BehaviorCategory.propTypes = {
  history: PropTypes.object,
  activityChallengeType: PropTypes.array,
  wellbeingCategoriesData: PropTypes.array,
  t: PropTypes.func
};

export default ((withTranslation()) (BehaviorCategory));
