/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Main } from '../AdminTeamCreation/styles';
import AboutChallengePopup from './AboutChallengePopup';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchBehaviorChallengeByCategory, getActivityChallengeById, deleteChallengeLibrary } from '../../redux/actions';
import _ from 'lodash';
import Waiting from '../Waiting';
import { ChallengeHeading } from "../CreateEvent/style";
import { ToolTip } from "../AdminTeamCreation/styles";
import HoverButton from '../common/HoverButtonV2';
import { BackIcon, CreateChallenge } from '../../utils/icons';
import {CategoryWrapper, CategoryCard} from './styles';
import Thumbnail from './../Thumbnail';
import { ImageUrl } from "../../utils/constants";
import { DeleteIcon, EditImage } from "../../utils/icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import DeletePopup from '../common/deletePopup';

class BehaviorChallengesTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAboutPopup:false,
      onHover: -1,
      showDeletePopup:false,
      deleteId:null,
      deleteType:null
    }
  }

  componentDidMount() {
    const { fethcChallengeByChallengeType, history } = this.props;
    const StringArray = history.location.pathname.split('/');
    if (StringArray[(StringArray.length - 1)] != '') {
      fethcChallengeByChallengeType(StringArray[(StringArray.length - 1)]);
    } else {
      fethcChallengeByChallengeType(StringArray[(StringArray.length - 2)]);
    }
  }

  componentDidUpdate(prevProps) {
    const { history, fethcChallengeByChallengeType } = this.props;
    const StringArray = history.location.pathname.split('/');
    [(StringArray.length - 1)]
    
    if (history.location.pathname !== prevProps.history.location.pathname) {
      if (StringArray[(StringArray.length - 1)] != '') {
        fethcChallengeByChallengeType(StringArray[(StringArray.length - 1)]);
      } else {
        fethcChallengeByChallengeType(StringArray[(StringArray.length - 2)]);
      }
    }
  }

  redirection = () => {
    const { history } = this.props;
    history.push('/company/challenges/create-activty')
  };

  showPastEvents = (path) => {
    this.props.history.push(path);
  };

  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  showAboutChallengePopup = (id) => {
    this.setState({
      showAboutPopup: true
    }, () => {
      this.props.getChallengeDetails(id);
    })
  };

  hideAboutChallengePopup = () => {
    this.setState({
      showAboutPopup: false
    })
  };

  showDeletePouup = (id, type) => {
    this.setState({
      showDeletePopup: true,
      deleteId:id,
      deleteType:type
    })
  };

  hideDeletePouup = () => {
    this.setState({
      showDeletePopup: false
    })
  };

  deleteLibraryData = () => {
    const obj={};
    obj['id']=this.state.deleteId;
    obj['library_type']=this.state.deleteType;
    // this.props.deleteChallengeLibrary(obj);
    this.setState(
      {
        showDeletePopup:false
      },
      () => {
        this.props.deleteChallengeLibrary("behaviour", this.state.deleteId);
      }
    );
  };

  popover = (text) => (
    <Popover id="popover-basic"
      style={{
        width: "100%",
        padding: "9px 14px",
        background: "#FFFFFF",
        border: "1px solid #002F47",
        boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
      }}
    >
      <div className='text' style={{
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#002F47",
        textAlign: "center",
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{text}
      </div>
    </Popover> );

  renderHeading = () => {
    // const StringArray = this.props.history.location.pathname.split('/');
    // const category = StringArray[(StringArray.length - 1)];
    const{history}=this.props;
    
    return(
      <ChallengeHeading>
        <ToolTip
          margin={"auto 15px auto 0"}
          activeValue={"transparent"}
          height="40px"
          width="55px"
        >
          <HoverButton
            title={this.props.t("Back")}
            width="24px"
            height="24px"
            svgPath={BackIcon()}
            onClick={()=>this.props.history.push({pathname: '/challenges/categories',query: {isCategory:true}})}
            backgroundColor={"#005C870D"}
            backgroundColorActive={"#005C87"}
          />
        </ToolTip>
        <span style={{textTransform:"capitalize"}}>{this.props.t(history?.location?.state?.name)} {this.props.t("Challenges You can Launch")} </span>
        {this.props.role=="ADMIN"&&<ToolTip
          margin={"auto 15px auto auto"}
          activeValue={"transparent"}
          height="40px"
          width="55px"
        >
          <HoverButton
            title={this.props.t("Create Challenge")}
            width="24px"
            height="24px"
            svgPath={<CreateChallenge/>}
            onClick={this.props.history.location.pathname.includes("/company/challenges/behavior-categories")?() => this.props.history.push('/company/challenges/create-behavior'):() => this.props.history.push('/challenges/create-behavior') }
            backgroundColor={"#005C870D"}
            backgroundColorActive={"#005C87"}
          />
        </ToolTip>}
      </ChallengeHeading>
    )
  };
    
  render() {
    const { challengeByType, challengeDetails, userName, history, role } = this.props;
    const { showAboutPopup, onHover, showDeletePopup, deleteId} = this.state;
    if (_.isNull(challengeByType)|| _.isUndefined(challengeByType)) {
      return <Waiting />
    }
    return (
      <Layout>
        <Main>
          {this.renderHeading()}
          <CategoryWrapper>
            {challengeByType && challengeByType.length ? challengeByType.map((category, index) => (
              <CategoryCard key={index} background={"white"} margin={(index+1)%3===0?"0 0px 25px 0":"0 25px 25px 0"}>
                <div className="image" onMouseEnter={() => this.onEnterMouse(category.id)} onMouseLeave={() => this.onLeaveMouse()}>
                  <Thumbnail
                    src={ImageUrl +"/"+category.image}
                    alt={"recommended card"}
                    height={"250px"}
                  />
                  {onHover === category.id &&
                    <div className='imageBackground'>
                      <div className="viewMore" onClick={history.location.pathname.includes("/company/challenges/behavior-categories/")?() => history.push(`/company/challenges/create-behavior-challenge/${category.id}`):() => history.push(`/challenges/create-behavior-challenge/${category.id}`)} >{this.props.t("Launch a Challenge")}</div>
                    </div>
                  }
                </div>
                <div className='wrapperMain'>
                  <div className="categoryNameWrapper">
                    <div className="categoryName" title={category.name}>{category.name}</div>
                    <div className="tooltipIcon">
                      <OverlayTrigger placement="bottom" overlay={this.popover(category.body)}>
                        <img src={ImageUrl+"/ChallengeDetailsScreen/toolTipIcon.png"} />
                      </OverlayTrigger>
                    </div>
                    <div className='wellnessActivityIcon'>
                      {<img src={ImageUrl+'/'+`ChallengeDetailsScreen/${category.category}.png`} />}
                    </div>
                    {/* <div className="challengeCount">{category.count}</div> */}
                  </div>
                  {role=="ADMIN"&&<div className='adminCards'>
                    <HoverButton
                      title={this.props.t("Edit Challenge")}
                      width="22px"
                      height="22px"
                      svgPath={<EditImage/>} 
                      onClick={history.location.pathname.includes("/company/challenges/behavior-categories/")?() => history.push(`/company/challenges/edit-behavior/${category.id}`):() => history.push(`/challenges/edit-behavior/${category.id}`)}
                      backgroundColor={"#005C870D"}
                      backgroundColorActive={"#005C87"}
                    />
                    <HoverButton
                      title={this.props.t("Delete Challenge")}
                      width="20px"
                      height="24px"
                      svgPath={<DeleteIcon/>} 
                      onClick={() => this.showDeletePouup(category.id,category.challenge_type)}
                      backgroundColor={"#005C870D"}
                      backgroundColorActive={"#005C87"}
                    />
                  </div>}
                </div>
                <div>
   
                </div>
              </CategoryCard>)):<h1>{this.props.t("No Data")}</h1>}
          </CategoryWrapper>
          {showAboutPopup &&<AboutChallengePopup show={showAboutPopup} challengeData={challengeDetails} userName={userName} hideAboutChallengePopup={this.hideAboutChallengePopup} history={history}/>}
          {showDeletePopup&& 
          <DeletePopup
            deleteId={deleteId}
            showConfirmPopup={showDeletePopup}
            showHideDeletePopup={this.hideDeletePouup}
            deleteData={this.deleteLibraryData}
            popupTitle="Delete Challenge"
            popupHeading="Are you sure you want to delete this Challenge?"
          />}
        </Main>
      </Layout>
    );
  }
}

BehaviorChallengesTypes.propTypes = {
  history: PropTypes.object,
  role: PropTypes.string,
  fethcChallengeByChallengeType: PropTypes.func,
  challengeByType: PropTypes.array,
  location: PropTypes.object.isRequired,
  getChallengeDetails: PropTypes.func,
  challengeDetails: PropTypes.array,
  userName: PropTypes.string,
  deleteChallengeLibrary: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  challengeByType: state.challenges.behaviorChallengeByType,
  challengeDetails: state.challenges.activityDetailsById,
  userName: state.profileData.firstName
})

const mapDispatchToProps = (dispatch) => ({
  fethcChallengeByChallengeType: (type) => dispatch(fetchBehaviorChallengeByCategory(type)),
  getChallengeDetails: (id) => dispatch(getActivityChallengeById(id)),
  deleteChallengeLibrary: (obj, type) => dispatch(deleteChallengeLibrary(obj, type))
})  

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BehaviorChallengesTypes));
