/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CategoryWrapper, CategoryCard, CreateChallengeCard } from './styles';
import Thumbnail from './../Thumbnail';
import { OverlayTrigger, Popover } from "react-bootstrap";
import CreateChallengePoup from './challengeCreatePopup';
import { ImageUrl } from "../../utils/constants";
import { withTranslation } from 'react-i18next';

class ActivityCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onHover: -1
    }
  }

  popover = (text) => (
    <Popover id="popover-basic"
      style={{
        width: "100%",
        padding: "9px 14px",
        background: "#FFFFFF",
        border: "1px solid #002F47",
        boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
      }}
    >
      <div style={{
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#002F47",
        textAlign: "center"
      }}>{text}
      </div>
    </Popover> );
  
  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  onLaunchChallenge = () => {
    this.props.history.push('/company/challenges/launch')
  };

  render() {
    const { onHover } = this.state;
    const { history, activityChallengeType, role, t } = this.props;
    return (
      <CategoryWrapper>
        {activityChallengeType && activityChallengeType.length && activityChallengeType.map((category, index) => (
          <CategoryCard key={index} background={"white"} margin={(index+1)%3===0?"0 0px 25px 0":"0 25px 25px 0"}>
            <div className="image" onMouseEnter={() => this.onEnterMouse(category.category_type)} onMouseLeave={() => this.onLeaveMouse()}>
              <Thumbnail
                src={ImageUrl +"/"+category.image}
                alt={"recommended card"}
                height={"250px"}
              />
              {onHover === category.category_type &&
                <div className='imageBackground'>
                  <div className="viewMore" onClick={history.location.pathname==="/challenges/categories"?() => history.push(`/challenges/categories/${category.category_type}`):() => history.push(`/company/challenges/categories/${category.category_type}`)}>{t("View More")}</div>
                </div>
              }
            </div>
            <div className="categoryNameWrapper">
              <div className="categoryName">{t(category.name)}</div>
              <div className="tooltipIcon">
                <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t(category.name))}>
                  <img src={ImageUrl+"/ChallengeDetailsScreen/toolTipIcon.png"} />
                </OverlayTrigger>
              </div>
              <div className="challengeCount">{category.count}</div>
            </div>
          </CategoryCard>))}
        {role=="ADMIN"&&<CreateChallengeCard>
          <div className='button' onClick={() =>this.props.history.push('/company/challenges/create-activty')}>{t("Create Challenge")}</div>
        </CreateChallengeCard>}
        {<CreateChallengePoup/>}
      </CategoryWrapper>
    );
  }
}

ActivityCategory.propTypes = {
  history: PropTypes.object,
  activityChallengeType: PropTypes.array,
  role: PropTypes.string,
  t: PropTypes.func
};

export default (withTranslation() (ActivityCategory));
